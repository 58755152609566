<template>
  <div>
    <!-- pic80171E41E6880BBC6C294C88077E2658.jpg -->
    <img src="@/assets/news-network/lu-partners-1.jpg" class="rounded" style="width: 440px; height: 247px">
    <p>
      Making LEGO Universe has always meant co-creating fun and friendship with LEGO fans, so the LEGO Universe News Network is proud to introduce LEGO Universe Partners, or “LUPs” for short! They’re a community of adult LEGO fans from all over earth that’s been closely partnering with LEGO Universe’s developers on the game’s content creation!
    </p>
    <p>
      At the recent second annual LUPs event, LEGO Universe’s developers hosted nearly 50 LUPs from six countries for four days of fun, learning and collaboration. Between playing with massive piles of LEGO bricks and having a blast doing building challenges, the LUPs got to learn how to use a digital tool that was custom made by LEGO Universe’s developers.
    </p>
    <p>
      The LUPs will continue building phenomenal physical and virtual models for LEGO Universe, and now they can mold the gameworld’s solids, liquids and gases to make massively fun environments! Why are the LUPs lending so much of their time and inspiring talent to the co-creation of LEGO Universe? <b>Just for the fun of it!</b>
    </p>
    <p>
      The entire LEGO Universe development team has a special message for the LUPs: Thank you for all the astounding artistry you’ve built into LEGO Universe already! Your love of LEGO is lighting up the gameworld!
    </p>
    <p>
      Very soon the LEGO Universe News Network will share a special video about the most recent LUPs event. Subscribe to our new RSS feed or keep checking back right here to see and hear it first! <br>In the meantime, here are a couple of sneak peek quotes from anonymous (for now) LUPs!
    </p>
    <p>
      <i>It’s fun; it’s hard work, but at the end of the day it’s for the kids and for the community. I think they’re going to be really happy with what everybody has put their heart and soul into!</i>
    </p>
    <p>
      <i>This place, this gameworld is going to be a rallying point for LEGO enthusiasts of every age and every nationality!</i>
    </p>
  </div>
</template>
